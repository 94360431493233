import React, { Component } from "react"
import { graphql } from "gatsby"
import HeaderDark from "../components/header/header-dark"
import SEO from "../components/seo"
import PortableText from "@sanity/block-content-to-react"
import {
  ContentWrapper,
  ContentIceberg,
  ContentBody,
  ContentTitle,
  ContentSidebar,
  ContentSidebarItem,
} from "../styled-components/inner-page-styles"
import { FloatingText } from "../styled-components/floating-text"
import { Global, css } from "@emotion/core"
import { TweenLite, Power3 } from "gsap"
import Video from "../components/video/video"

class InnerPage extends Component {
  componentDidMount() {
    document.querySelector("body").style.backgroundColor = "#eff0f2"

    if (window.matchMedia("(min-width: 1024px)").matches) {
      setTimeout(() => {
        const headers = document.querySelectorAll(".animate-in h2")
        let count = 1
        headers.forEach(h2 => {
          Object.assign(h2.nextElementSibling.style, {
            marginTop: `-${h2.clientHeight + 8}px`,
          })
          h2.id = "heading-" + count
          count = count + 1
        })
      }, 500)
    }

    const sidebar = document.querySelector(".sidebar")
    const iceberg = document.querySelector(".iceberg")
    sidebar.style.left = `${iceberg.offsetLeft}px`

    window.addEventListener("resize", () => {
      sidebar.style.left = `${iceberg.offsetLeft}px`
    })

    const wrappers = document.querySelectorAll(".animate-in *:not(h2)")
    const wrappers_h2 = document.querySelectorAll(".animate-in h2")

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          TweenLite.to(entry.target, 1.4, {
            opacity: 1,
            y: 0,
            ease: Power3.easeInOut,
          })
        }
      })
    })

    const observer_h2 = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          TweenLite.to(entry.target, 1.4, {
            opacity: 1,
            x: 0,
            ease: Power3.easeInOut,
            onComplete: () => {
              entry.target.classList.add("visible")
            },
          })
        }
      })
    })

    if (window.matchMedia("(min-width: 1024px)").matches) {
      wrappers.forEach(content => {
        TweenLite.set(content, {
          opacity: 0,
          y: "64px",
        })
        observer.observe(content)
      })

      wrappers_h2.forEach(content => {
        TweenLite.set(content, {
          opacity: 0,
          x: "-64px",
        })
        observer_h2.observe(content)
      })
    }

    document.querySelectorAll("video").forEach(video => {
      video.addEventListener("click", e => {
        if (e.target.paused) {
          e.target.play()
        } else {
          e.target.pause()
        }
      })
    })
  }

  findHeading(e, val) {
    e.preventDefault()
    const wrappers_h2 = document.querySelectorAll("h2")
    wrappers_h2.forEach(element => {
      if (element.innerHTML === val) {
        var topOfElement = element.offsetTop
        window.scroll({ top: topOfElement, behavior: "smooth" })
      }
    })
  }

  render() {
    const { data } = this.props
    const post = data.sanityPage
    let spy = []
    post.sidebar.forEach((item, index) => {
      spy.push("heading-" + index)
    })
    return (
      <>
        <Global
          styles={css`
            body {
              background-color: #eff0f2;
            }
          `}
        />
        <SEO title={post.title} />
        <HeaderDark />
        <FloatingText
          css={css`
            color: #1e1e1e;
          `}
        >
          we can help
        </FloatingText>
        <ContentSidebar
          items={spy}
          className="sidebar"
          currentClassName="is-current"
        >
          {post.sidebar.map((h2, index) => (
            <ContentSidebarItem after={h2} key={index}>
              <a onClick={e => this.findHeading(e, h2)} href={"#" + h2}>
                {index < 9 ? `0${index + 1}` : index + 1}
              </a>
            </ContentSidebarItem>
          ))}
        </ContentSidebar>
        <ContentIceberg className="iceberg">{post.iceberg}</ContentIceberg>
        <ContentWrapper className="content-wrapper">
          <ContentTitle>{post.title}</ContentTitle>
          <ContentBody className="animate-in">
            <PortableText
              blocks={post._rawContent}
              serializers={{
                types: {
                  ptImage: ({ node }) => (
                    <img src={node.image.asset.url} alt={node.alt} />
                  ),
                  ptVideo: ({ node }) => (
                    <Video
                      vidSrc={node.video.asset.url}
                      autoplay={false}
                      controls={false}
                      muted={false}
                      poster={node.poster.asset.url}
                    />
                  ),
                },
              }}
            />
          </ContentBody>
        </ContentWrapper>
      </>
    )
  }
}

export const query = graphql`
  query($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      iceberg
      sidebar
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default InnerPage
